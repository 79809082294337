import React from "react";
import { Routes, Route, Navigate, redirect } from "react-router-dom";
import Login from "./pages/Login";
import { commonRoutes } from "./routes/routes";
import Service from "./service/Service";
import Requests from "./pages/Requests";
import { StyledEngineProvider, ThemeProvider, CssBaseline } from "@mui/material";
import { AudnastTheme } from "./styles/AudnastTheme";
import ProtectedLayout from "./components/Common";
import { ConfigProvider } from "./contexts/ConfigContext";
import { User } from "./models/ServiceRequests";
import Overview from "./pages/Overview";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "moment/locale/is";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
interface IAppState {
    user: User | undefined;
    error: string;
}

interface IAppProps {}

class App extends React.Component<IAppProps, IAppState> {
    private service: Service;

    constructor(props: IAppProps) {
        super(props);

        this.service = new Service(this.handleUnauthorizedRequest);

        this.state = {
            user: this.service.getUserIfLoggedIn(),
            error: "",
        };
    }

    private handleUnauthorizedRequest = () => {
        this.setState({ user: undefined }, () => {
            redirect(
                `/${commonRoutes.login.root}?${commonRoutes.login.params.errorMessage}=Your session has expired, please login again`
            );
        });
    };

    private logIn = (token: string) => {
        let user = this.service.setTokenAndGetUser(token);
        this.setState({ user }, () => {
            redirect(`/`);
        });
    };

    private logOut = () => {
        this.service.logOut();
        this.setState({ user: undefined }, () => {
            redirect(`/${commonRoutes.login.root}`);
        });
    };

    public render() {
        return (
            <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"is"}>
                    <ThemeProvider theme={AudnastTheme}>
                        <CssBaseline />
                        <Routes>
                            <Route
                                path="/login"
                                element={
                                    <>
                                        <ConfigProvider>
                                            {this.state.user !== undefined && <Navigate to="/" replace />}
                                            <Login setToken={this.logIn} prepareLogin={this.service.prepareLogin} />
                                        </ConfigProvider>
                                    </>
                                }
                            />
                            <Route path="/" element={<ProtectedLayout user={this.state.user} logOut={this.logOut} />}>
                                <Route index element={<Navigate to="/requests" />} />
                                <Route
                                    path={"/requests"}
                                    element={
                                        <Requests
                                            submitPatient={this.service.submitPatient}
                                            submitRequest={this.service.submitRequest}
                                            updateTimeAllocated={this.service.updateTimeAllocated}
                                            getRequests={this.service.getRequests}
                                            getRequestTimeLog={this.service.getRequestTimeLog}
                                            getEmployees={this.service.getEmployees}
                                            getKtInfo={this.service.getKtInfo}
                                            // getTypes={this.service.getTypes}
                                            user={this.state.user}
                                        />
                                    }
                                />
                                <Route
                                    path={"/overview"}
                                    element={
                                        <Overview
                                            getRequests={this.service.getRequests}
                                            user={this.state.user}
                                            getRequestTimeLog={this.service.getRequestTimeLog}
                                        />
                                    }
                                />
                            </Route>
                        </Routes>
                    </ThemeProvider>
                </LocalizationProvider>
            </StyledEngineProvider>
        );
    }
}

export default App;
