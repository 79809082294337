import { useEffect, useState } from "react";
import {
    // AllRequestTypes,
    EmployeeOptions,
    NewPatient,
    NewServiceRequest,
    Patient,
    PostNewServiceRequest,
    ServiceRequest,
    ServiceRequestSchema,
    ThjodskraLeit,
    TimeLog,
    UpdateTimeAllocated,
    User,
} from "../models/ServiceRequests";
import { useAsync } from "../utils/UseAsync";
import Message from "../components/Message";
import EnhancedTable from "../components/EnhancedTable";
import AddIcon from "@mui/icons-material/Add";
import { Alert, IconButton, Modal, Paper, Snackbar, useTheme } from "@mui/material";
import BouncingDotsLoader from "../components/DotLoader";
import { PatientForm } from "../components/PatientForm";
import { RequestForm } from "../components/RequestForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import moment from "moment";
import DatePeriod from "../components/DatePeriod";

export interface RequestsPageProps {
    submitPatient: (pat: NewPatient) => Promise<Patient>;
    submitRequest: (req: PostNewServiceRequest) => Promise<string>;
    updateTimeAllocated: (req: UpdateTimeAllocated) => Promise<void>;
    getRequests: () => Promise<ServiceRequest[]>;
    getRequestTimeLog: (reqID: string) => Promise<TimeLog[]>;
    getEmployees: () => Promise<EmployeeOptions>;
    getKtInfo: (kt: string) => Promise<ThjodskraLeit>;
    user?: User;
}
export interface ToastInterface {
    message: string;
    type: "success" | "error";
}

function Requests({
    getRequests,
    getRequestTimeLog,
    // getTypes,
    submitPatient,
    submitRequest,
    getEmployees,
    getKtInfo,
    updateTimeAllocated,
    user,
}: RequestsPageProps) {
    // const { status: typesStatus, value: types, error: typesError } = useAsync(getTypes);
    console.log("hallo");

    const {
        status: employeesStatus,
        value: employeeResponse,
        error: employeesError,
        execute: reloadEmployees,
    } = useAsync(getEmployees, true, false);

    const [reqForm, setReqForm] = useState(true);
    const {
        status: reqStatus,
        value: requests,
        error: reqError,
        execute: trigger,
    } = useAsync(getRequests, true, false);
    const [toastProps, setToastProps] = useState<ToastInterface>();

    const handleClose = () => {
        reqForm ? setOpen(false) : setReqForm(true);
        setActiveReq(undefined);
    };

    const handleToast = (props: ToastInterface) => {
        setToastProps(props);
        setToastOpen(true);
        setTimeout(() => {
            setToastOpen(false);
        }, 6000);
    };

    const [open, setOpen] = useState(false);
    const [activeReq, setActiveReq] = useState<ServiceRequest | undefined>(undefined);

    const theme = useTheme();

    const empty: NewServiceRequest = {
        account: user ? user.accountName : "",
        description: "",
        timeAllocated: "",
        timeUsed: "",
        patient: {
            id: "",
            ssn: "",
            title: "",
        },
        descriptiveTitle: "",
        requestedBy: "",
        hRRepresentativeID: user ? user.id : "",
        // typeID: "",
    };
    const {
        handleSubmit,
        formState: { isSubmitting, errors },
        control,
        register,
        setValue,
        reset,
        watch,
        setError,
    } = useForm({
        resolver: zodResolver(ServiceRequestSchema),
        defaultValues: empty,
    });
    useEffect(() => {
        const defaultValues: NewServiceRequest = activeReq
            ? {
                  account: activeReq.account,
                  description: activeReq.description,
                  timeAllocated: activeReq.timeAllocated,
                  timeUsed: activeReq.timeUsed,
                  patient: activeReq.patient,
                  descriptiveTitle: activeReq.descriptiveTitle,
                  hRRepresentativeID: activeReq.responsible.id,
                  requestedBy: activeReq.requestedBy,
                  //   typeID: activeReq.type,
              }
            : empty;
        reset(defaultValues);
    }, [activeReq, user, reset, open]);

    const onSubmitHandler: SubmitHandler<NewServiceRequest> = async (values: NewServiceRequest) => {
        console.log("subbing, ", values);
        return submitRequest({
            ...values,
            timeAllocated: parseInt(values.timeAllocated) || 0,
        })
            .then((res) => {
                trigger();
                handleClose();
                handleToast({ message: "Beiðni mótekin", type: "success" });
            })
            .catch((err: string | Error) => {
                handleToast({
                    message: (typeof err == "string" ? err : err.message) || "Eitthvað fór úrskeiðis við að vista",
                    type: "error",
                });
            });
    };

    const updateTimeAllocatedHandler = async () => {
        const timeAllocated = Number(activeReq?.timeAllocated || "");
        const timeUsed = parseFloat(activeReq?.timeUsed.replaceAll(",", ".") || "");
        const newTimeAllocated = Number(watch(["timeAllocated"])[0]);
        if (newTimeAllocated < timeUsed) {
            setError("timeAllocated", {
                type: "custom",
                message: "Ekki er hægt úthluta minni tíma en notaður hefur verið",
            });
            return;
        } else if (newTimeAllocated < 1) {
            setError("timeAllocated", {
                type: "custom",
                message: "Úthluta verður að minnsta kosti 1 klst.",
            });
            return;
        }
        if (timeAllocated === newTimeAllocated) {
            return;
        }

        try {
            setIsSubmittingTime(true);
            await updateTimeAllocated({
                id: `${activeReq?.id}`,
                occurences: newTimeAllocated,
            });
            trigger();
            handleClose();
            handleToast({ message: "Tími á beiðni hefur verið uppfærður", type: "success" });
            setIsSubmittingTime(false);
        } catch (err) {
            handleToast({
                message: typeof err == "string" ? err : "Eitthvað fór úrskeiðis við að vista",
                type: "error",
            });
        }
    };

    const handleCloseToast = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };
    const [toastOpen, setToastOpen] = useState(false);
    const [isSubmittingTime, setIsSubmittingTime] = useState(false);
    const [requestsFiltered, setRequestsFiltered] = useState<ServiceRequest[] | null>(null);
    useEffect(() => {
        if (requestsFiltered === null) setRequestsFiltered(requests);
    }, [requests, requestsFiltered]);
    return (
        <div style={{ flexGrow: 1 }}>
            <DatePeriod requests={requests} dateCol={"modified"} setRequestsFiltered={setRequestsFiltered} />
            {reqStatus === "pending" && requests === null && <BouncingDotsLoader color={theme.palette.primary.main} />}
            {reqStatus === "error" && reqError !== null && <Message message={reqError || "errors"} />}
            {user && (
                <>
                    <EnhancedTable
                        items={requestsFiltered || []}
                        searchFields={["title", "dateCreated", "patient.title", "status", "responsible.title"]}
                        columns={[
                            { id: "title", label: "Númer beiðnar" },
                            { id: "descriptiveTitle", label: "Beiðni" },
                            {
                                id: "dateCreated",
                                label: "Stofnað",
                                onRender: (itm) => (
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        {`${moment(itm.modified).format("D.MMM Y")}  `}
                                    </span>
                                ),
                            },
                            { id: "responsible.title", label: "Stofnað af" },
                            { id: "timeAllocated", label: "Úthlutað" },
                            { id: "timeUsed", label: "Notað" },
                            { id: "status", label: "Staða" },
                        ]}
                        idCol="id"
                        onClick={(_ev, it) => {
                            setActiveReq(it);
                            setOpen(true);
                        }}
                        customBtn={
                            <IconButton
                                aria-label="add"
                                size="small"
                                sx={{
                                    p: "9px",
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    ":hover": { backgroundColor: "#2481b7" },
                                    width: "56px",
                                }}
                                onClick={() => setOpen(true)}
                            >
                                <AddIcon sx={{ color: "#ffffff" }} />
                            </IconButton>
                        }
                    />
                    <Modal
                        onClose={handleClose}
                        open={open}
                        sx={{
                            backdropFilter: "blue",
                            "& .MuiBackdrop-root": {
                                backgroundColor: "#f5f7faad",
                            },
                        }}
                    >
                        <Paper
                            sx={{
                                width: { xs: "100vw", sm: "600px", md: "740px" },
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                borderRadius: "10px",
                                boxShadow: "0px 0px 23px rgba(0, 0, 0, 0.1)",
                                p: 4,
                            }}
                        >
                            {employeeResponse &&
                                (reqForm ? (
                                    <RequestForm
                                        setReqForm={setReqForm}
                                        data={activeReq}
                                        // types={types}
                                        employeeopt={employeeResponse}
                                        closeForm={handleClose}
                                        trigger={trigger}
                                        control={control}
                                        errors={errors}
                                        handleSubmit={handleSubmit(onSubmitHandler, () => console.log("errddor"))}
                                        isSubmitting={isSubmitting || isSubmittingTime}
                                        btnDisabled={activeReq?.timeAllocated === watch(["timeAllocated"])[0]}
                                        register={register}
                                        watch={watch}
                                        setValue={setValue}
                                        updateTimeAllocated={updateTimeAllocatedHandler}
                                        getRequestTimeLog={getRequestTimeLog}
                                        accountName={user.accountName}
                                    />
                                ) : (
                                    <PatientForm
                                        submitPatient={submitPatient}
                                        setReqForm={setReqForm}
                                        user={user}
                                        reloadEmployees={reloadEmployees}
                                        setNewEmployee={setValue}
                                        handleToast={handleToast}
                                        getKtInfo={getKtInfo}
                                    />
                                ))}
                        </Paper>
                    </Modal>
                    <Snackbar open={toastOpen} autoHideDuration={6000} onClose={handleCloseToast}>
                        <Alert onClose={handleCloseToast} severity={toastProps?.type} sx={{ width: "100%" }}>
                            {toastProps?.message || ""}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </div>
    );
}

export default Requests;
