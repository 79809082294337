import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import {
    NewPatient,
    NewPatientSchema,
    NewServiceRequest,
    Patient,
    ThjodskraLeit,
    User,
} from "../models/ServiceRequests";
import CustomIconButton from "./Button";
import CloseIcon from "@mui/icons-material/Close";
import { TextInput } from "./TextInput";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm, UseFormSetValue } from "react-hook-form";
import { ToastInterface } from "../pages/Requests";
import { useAsync } from "../utils/UseAsync";
import { useCallback, useEffect, useState } from "react";

type PatientFormProps = {
    submitPatient: (req: NewPatient) => Promise<Patient>;
    setReqForm: React.Dispatch<React.SetStateAction<boolean>>;
    user: User;
    reloadEmployees: () => Promise<void>;
    getKtInfo: (kt: string) => Promise<ThjodskraLeit>;
    setNewEmployee: UseFormSetValue<NewServiceRequest>;
    handleToast: (props: ToastInterface) => void;
};

export const PatientForm = ({
    submitPatient,
    setReqForm,
    user,
    reloadEmployees,
    getKtInfo,
    setNewEmployee,
    handleToast,
}: PatientFormProps) => {
    const defaultValues: NewPatient = {
        ssn: "",
        title: "",
        phone: "",
        email: "",
        account: user.accountName,
    };
    const {
        handleSubmit,
        formState: { isSubmitting, errors },
        control,
        register,
        setValue,
        getValues,
        watch,
        setError,
    } = useForm({
        mode: "onBlur",
        resolver: zodResolver(NewPatientSchema),
        defaultValues,
    });

    const onSubmitHandler: SubmitHandler<NewPatient> = (values: NewPatient) => {
        return submitPatient({
            ...values,
        })
            .then((res) => {
                reloadEmployees().then(() => setNewEmployee("patient", res));
                setReqForm(true);
                handleToast({ message: "Nýjum starfsmanni var bætt við", type: "success" });
            })
            .catch((err: string | Error) => {
                handleToast({
                    message: (typeof err == "string" ? err : err.message) || "Eitthvað fór úrskeiðis við að vista",
                    type: "error",
                });
            });
    };

    const [prevSearch, setPrevSearch] = useState("");

    const wrappedGetKtInfo = useCallback(() => getKtInfo(getValues("ssn")), [getKtInfo, getValues]);
    const { status, value: personInfo, execute: getPersonInfo } = useAsync(wrappedGetKtInfo, false, true);
    useEffect(() => {
        if (watch("ssn").length === 10 && status !== "pending" && getValues("ssn") !== prevSearch) {
            setPrevSearch(getValues("ssn"));
            getPersonInfo();
        }
        if (watch("ssn").length === 10 && personInfo?.name && personInfo?.name !== getValues("title")) {
            setValue("title", personInfo.name);
        }
        if (status === "error") {
            setError("ssn", { message: "Kennitala finnst ekki í þjóskrá" });
        }
    }, [personInfo, watch("ssn"), status]);
    return (
        <>
            <IconButton
                size="small"
                sx={{
                    p: "2px",
                    position: "absolute",
                    right: "16px",
                    top: "16px",
                }}
                onClick={() => setReqForm(true)}
            >
                <CloseIcon />
            </IconButton>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h5">{"Nýr skjólstæðingur"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
                    <TextInput
                        label="Kennitala skjólstæðings"
                        register={register}
                        name={"ssn"}
                        error={errors["ssn"]}
                        setVal={setValue}
                    />
                    {status === "pending" && (
                        <Box sx={{ position: "absolute", right: "8px", bottom: "8px" }}>
                            <CircularProgress size="1.75rem" />
                        </Box>
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextInput
                        label="Nafn skjólstæðings"
                        register={register}
                        name={"title"}
                        error={errors["title"]}
                        InputLabelProps={{ shrink: watch("title") ? true : undefined }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        label="Sími skjólstæðings"
                        register={register}
                        name={"phone"}
                        error={errors["phone"]}
                        setVal={setValue}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        label="Netfang skjólstæðings"
                        register={register}
                        name={"email"}
                        error={errors["email"]}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput label="Fyrirtæki" register={register} name={"account"} disabled />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput label="Svið" register={register} name={"svid"} error={errors["svid"]} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput label="Deild" register={register} name={"deild"} error={errors["deild"]} />
                </Grid>
                <Grid item xs={12}>
                    <CustomIconButton
                        onClick={handleSubmit(onSubmitHandler)}
                        title={"Vista nýjan skjólstæðing"}
                        variant="contained"
                        sx={{
                            width: "100%",
                            borderRadius: "10px",
                            height: "56px",
                            "&.Mui-disabled": {
                                backgroundColor: (theme) => theme.palette.primary.main,
                            },
                            boxShadow: "none",
                        }}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                    />
                </Grid>
            </Grid>
        </>
    );
};
