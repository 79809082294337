import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SwipeableDrawer,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import MenuBurger from "../assets/icons/menuburger.svg";
import Logout from "../assets/icons/logout.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import { User } from "../models/ServiceRequests";

interface MobileTabsProps {
  mobile: boolean;
  toggleDrawer?: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export interface IHeaderProps<T extends User> {
  user: T | undefined;
  logOut: () => void;
  Tabs: React.FC<MobileTabsProps>;
  headerImg: JSX.Element;
}

export interface IHeaderState {
  anchorElement: HTMLElement | undefined;
}

export function HeaderComponent<T extends User>({
  user,
  logOut,
  Tabs,
  headerImg,
}: IHeaderProps<T>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawer, setDrawer] = useState<boolean>(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawer(open);
    };
  const theme = useTheme();

  let userName = user?.name || "";
  if (userName.length > 20) {
    const names = userName.split(" ");
    if (names[0].length < 15) {
      userName = names[0];
    } else if (names[names.length - 1].length < 15) {
      userName = names[names.length - 1];
    } else {
      userName = "User";
    }
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <header>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          px: 2,
          mb: 8,
        }}
      >
        <Box sx={{ height: "50px" }}>{headerImg}</Box>
        <IconButton
          aria-label="burger"
          disableRipple
          sx={{
            p: { md: 0 },
            display: { xs: "flex", sm: "none" },
            alignSelf: "center",
          }}
          onClick={toggleDrawer(true)}
        >
          <Box
            component={"img"}
            src={MenuBurger}
            height="24px"
            alt="MenuBurger"
          />
        </IconButton>

        <Stack
          direction={"row"}
          sx={{
            flex: 1,
            justifyContent: "space-between",
            display: { xs: "none", sm: "flex" },
          }}
        >
          <Tabs mobile={false} />
          <Box>
            <Button
              onClick={handleClick}
              endIcon={
                <ArrowDropDownIcon color="secondary" fontSize="inherit" />
              }
              size="large"
              disableElevation
              sx={{
                color: "#000000",
                fontWeight: 700,
                fontSize: theme.typography.pxToRem(16),
                p: 0,
              }}
              disableRipple
            >
              {userName}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={logOut}>Útskrá</MenuItem>
            </Menu>
          </Box>
        </Stack>
      </Stack>
      <SwipeableDrawer
        anchor={"right"}
        open={drawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Stack
          sx={{
            width: { xs: "100vw", sm: "70vw", justifyContent: "space-between" },
            height: "100vh",
            p: theme.spacing(4),
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <IconButton
            sx={{
              height: "40px",
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
            aria-label="close-modal"
            onClick={toggleDrawer(false)}
          >
            <CloseIcon sx={{ height: "25px", color: "#ffffff" }} />
          </IconButton>
          <Tabs mobile={true} toggleDrawer={toggleDrawer} />
          <Stack>
            <Button
              variant={"outlined"}
              color="info"
              sx={{ width: "100%", borderColor: "#ffffff" }}
              onClick={logOut}
            >
              <Box
                component={"img"}
                src={Logout}
                height="24px"
                alt="logout"
                sx={{ pr: 1 }}
              />
              Útskrá
            </Button>
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </header>
  );
}

export default HeaderComponent;
