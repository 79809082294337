import React, { useState, useMemo } from "react";
import {
    styled,
    Collapse,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField,
    useTheme,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import find from "lodash.find";
import get from "lodash.get";
import { Leaves } from "../utils/utils";

export interface EnhancedTableProps<T> {
    items: T[];
    columns: Column<T>[];
    searchFields: Leaves<T, 3>[];
    idCol?: keyof T;
    title?: string;
    groupByKey?: keyof T;
    onClick: (event: React.MouseEvent<unknown>, item: T) => void;
    disableSort?: boolean;
    customBtn?: JSX.Element;
}

interface Column<T> {
    id: Leaves<T, 3>;
    label: string;
    numeric?: boolean;
    disablePadding?: boolean;
    onRender?: (item: T, key: Column<T>["id"]) => JSX.Element | React.ReactText;
}

function descendingComparator<T>(a: T, b: T, orderBy: Column<T>["id"]) {
    if (get(b, orderBy) < get(a, orderBy)) {
        return -1;
    }
    if (get(b, orderBy) > get(a, orderBy)) {
        return 1;
    }
    return 0;
}

type Order = "asc" | "desc";

function getComparator<T>(order: Order, orderBy: Column<T>["id"]): (a: T, b: T) => number {
    return order === "desc"
        ? (a, b) => descendingComparator<T>(a, b, orderBy)
        : (a, b) => -descendingComparator<T>(a, b, orderBy);
}

// eslint-disable-next-line @typescript-eslint/ban-types
function stableSort<T extends Object>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line @typescript-eslint/ban-types
interface TableHeaderProps<T extends Object | (Object & { ID: number })> {
    columns: Column<T>[];
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: Column<T>["id"]) => void;
    order: Order;
    orderBy: Column<T>["id"];
    rowCount: number;
    disableSort?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function EnhancedTableHead<T extends Object | (Object & { ID: number })>(props: TableHeaderProps<T>) {
    const { columns, order, orderBy, onRequestSort, disableSort = false } = props;
    const createSortHandler = (property: Column<T>["id"]) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const theme = useTheme();

    return (
        <TableHead>
            <TableRow>
                {columns.map((headCell, idx) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={idx == 0 ? { paddingLeft: 16 } : {}}
                        sx={{
                            fontWeight: 700,
                            "& .Mui-active": {
                                "& .MuiTableSortLabel-icon": {
                                    color: `${theme.palette.secondary.main} !important`,
                                },
                            },
                        }}
                    >
                        {!disableSort && (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span
                                        style={{
                                            border: 0,
                                            clip: "rect(0 0 0 0)",
                                            height: 1,
                                            margin: -1,
                                            overflow: "hidden",
                                            padding: 0,
                                            position: "absolute",
                                            top: 20,
                                            width: 1,
                                        }}
                                    >
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        )}
                        {disableSort && headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    title?: string;
    onSearch: (searchVal: string) => void;
    customBtn?: JSX.Element;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { title, onSearch, customBtn } = props;

    return (
        <Stack direction={"row"} sx={{ display: "flex", mb: 3, justifyContent: "space-between" }}>
            <Stack
                direction={"row"}
                sx={{
                    position: "relative",
                    display: "flex",
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 20px 0px #0000000D",
                    mr: 2,
                }}
            >
                <TextField
                    sx={{
                        width: "100%",
                        m: 0,
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: 0,
                        },
                    }}
                    label="Leita"
                    variant="outlined"
                    margin="dense"
                    onChange={(ev) => {
                        onSearch(ev.target.value);
                    }}
                />
                <SearchIcon color="secondary" sx={{ position: "absolute", right: 18, top: 18 }} />
            </Stack>
            {customBtn}
        </Stack>
    );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
interface EnhancedTableRowsProps<T> {
    items: T[];
    order: Order;
    orderBy: Column<T>["id"];
    isSelected: (name: T[keyof T]) => boolean;
    idCol: keyof T;
    handleClick: (event: React.MouseEvent<unknown>, item: T) => void;
    columns: Column<T>[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
function EnhancedTableRows<T extends Object>({
    items,
    order,
    orderBy,
    isSelected,
    idCol,
    handleClick,
    columns,
}: EnhancedTableRowsProps<T>) {
    return (
        <>
            {stableSort<T>(items, getComparator<T>(order, orderBy)).map((itm, index) => {
                const isItemSelected = isSelected(itm[idCol]);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                    <StyledTableRow
                        hover
                        onClick={(event: React.MouseEvent<unknown>) => handleClick(event, itm)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={itm[idCol] as any as string}
                        selected={isItemSelected}
                        style={{ cursor: "pointer" }}
                    >
                        {columns.map((col, idx) => {
                            const onRender: Column<T>["onRender"] = col.onRender ?? ((itm, key) => get(itm, key));
                            return (
                                <TableCell
                                    {...(idx == 0 ? { component: "th", id: labelId, scope: "row" } : {})}
                                    key={col.id}
                                    style={{
                                        ...(idx == 0 ? { paddingLeft: 16 } : {}),
                                    }}
                                >
                                    {onRender(itm, col.id)}
                                </TableCell>
                            );
                        })}
                    </StyledTableRow>
                );
            })}
        </>
    );
}

// eslint-disable-next-line @typescript-eslint/ban-types
function EnhancedTable<T extends Object | (Object & { ID: number })>(props: EnhancedTableProps<T>) {
    const {
        columns,
        items,
        idCol: explicitID,
        title,
        groupByKey,
        onClick,
        disableSort = false,
        searchFields,
        customBtn,
    } = props;
    const [order, setOrder] = useState<Order>("desc");
    const [searchVal, setSearchVal] = useState<string>("");
    const [orderBy, setOrderBy] = useState<Column<T>["id"]>(columns[3].id as Column<T>["id"]);
    const [openGroups, setOpenGroups] = useState<string[]>([]);
    const [selected, _setSelected] = useState<any[]>([]);
    const idCol: keyof T = useMemo(() => {
        if (explicitID) {
            return explicitID;
        }
        const itemObject = Object.prototype.hasOwnProperty.call(items[0], "ID");
        if (itemObject) {
            return "ID" as keyof T;
        } else throw new Error("No ID given for <EnhancedTable/>");
    }, [items, columns]);

    const groups: { Title: string; items: T[] }[] | undefined = useMemo(() => {
        if (!groupByKey) return undefined;
        return items
            .reduce<{ Title: string; items: T[] }[]>((groups, curr) => {
                const currGroupTitle: string = (curr[groupByKey] as any as { title: string })?.title || "Other";

                let group: { Title: string; items: T[] } | undefined = find(
                    groups,
                    (grp) => grp.Title == currGroupTitle
                );

                if (!group) {
                    group = { Title: currGroupTitle, items: [] };
                    groups.push(group);
                }

                group.items.push(curr);
                return groups;
            }, [])
            .sort((a, b) => (a.Title == "Other" ? 1 : b.Title == "Other" ? -1 : a.Title.localeCompare(b.Title)));
    }, [items, groupByKey]);

    const openGroup = (groupName: string) => {
        const newOpenGroups = openGroups.concat(groupName);
        setOpenGroups(newOpenGroups);
    };

    const closeGroup = (groupToCloseName: string) => {
        setOpenGroups(openGroups.filter((groupName) => groupName != groupToCloseName));
    };

    const handleRequestSort = (_event: React.MouseEvent<unknown>, property: Column<T>["id"]) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const isSelected = (name: T[keyof T]) => selected.indexOf(name) !== -1;
    const theme = useTheme();
    return (
        <div style={{ width: "100%" }}>
            <EnhancedTableToolbar
                title={title}
                numSelected={selected.length}
                onSearch={(newVal: string) => {
                    setSearchVal(newVal.toLowerCase());
                }}
                customBtn={customBtn}
            />
            <Paper
                sx={{
                    borderRadius: "10px",
                    boxShadow: "0px 0px 20px 0px #0000000D",
                    border: `1px solid ${theme.palette.grey[200]}`,
                    width: "100%",
                    marginBottom: theme.spacing(2),
                }}
            >
                <TableContainer>
                    <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                        <EnhancedTableHead
                            columns={columns}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={items.length}
                            disableSort={disableSort}
                        />
                        <TableBody>
                            {groups &&
                                groups.map((grp) => {
                                    const isOpen = openGroups.indexOf(grp.Title) != -1;
                                    return (
                                        <React.Fragment key={grp.Title}>
                                            <TableRow
                                                hover
                                                style={{ cursor: "pointer" }}
                                                onClick={() => (isOpen ? closeGroup(grp.Title) : openGroup(grp.Title))}
                                                key={`${grp.Title}-header`}
                                            >
                                                <TableCell padding="checkbox" key="expander">
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        style={{ padding: 9 }}
                                                    >
                                                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell
                                                    colSpan={columns.length}
                                                    style={{ fontWeight: 600 }}
                                                    key="title"
                                                >
                                                    {grp.Title} ({grp.items.length})
                                                </TableCell>
                                            </TableRow>
                                            <TableRow key={`${grp.Title}-content`}>
                                                <TableCell style={{ padding: 0 }} colSpan={columns.length + 1}>
                                                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                                        <Table
                                                            style={{
                                                                backgroundColor: "rgba(126,135,140,0.15)",
                                                            }}
                                                        >
                                                            <EnhancedTableRows
                                                                items={grp.items}
                                                                order={order}
                                                                orderBy={orderBy}
                                                                isSelected={isSelected}
                                                                idCol={idCol}
                                                                handleClick={onClick}
                                                                columns={columns}
                                                            />
                                                        </Table>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            {!groups && (
                                <EnhancedTableRows
                                    items={items.filter((itm) =>
                                        searchFields.some((path) => {
                                            let val: string | number = get(itm, path, "") ?? "";
                                            if (typeof val == "number") val = val.toString();
                                            return val.toLowerCase().indexOf(searchVal) != -1;
                                        })
                                    )}
                                    order={order}
                                    orderBy={orderBy}
                                    isSelected={isSelected}
                                    idCol={idCol}
                                    handleClick={onClick}
                                    columns={columns}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default EnhancedTable;
