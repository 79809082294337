// When in doubt, use ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import crypto from 'crypto-browserify';

export const generateHashString = (loginHint: string) => {
  // Create a unique string by combining the loginHint and current time
  const uniqueString = loginHint + Date.now().toString();

  // Calculate the  SHA256 hash of the unique string
  const sha256Hash = crypto.createHash('sha512').update(uniqueString).digest('base64');

  return sha256Hash;
};

export const calculateVcode = (hashString: string) => {
  const hash = Buffer.from(hashString, 'base64');
  const sha256Hash = crypto.createHash('sha256').update(hash).digest();

  const lastTwoBytes = sha256Hash.slice(-2);
  const code = lastTwoBytes.readUInt16BE(0);

  return String(code % 10000).padStart(4, '0');
};