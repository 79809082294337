import { keyframes, styled } from "@mui/material";
const bounch = keyframes`
to {
    opacity: 0.1;
}
0% { transform: translateY(8px); }
100% { transform: translateY(-8px); }
`;
const DotContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  "div:nth-of-type(2)": {
    animationDelay: "0.2s",
  },
  "div:nth-of-type(3)": {
    animationDelay: "0.4s",
  },
  "div:nth-of-type(1)": {
    marginLeft: "6px",
  },
}));

const Dot = styled("div")(() => ({
  width: "16px",
  height: "16px",
  margin: "3px 6px",
  borderRadius: "50%",
  backgroundColor: "#ffffff",
  opacity: "1",
  animation: `${bounch} 0.6s infinite alternate`,
}));

const BouncingDotsLoader = ({ color }: { color?: string }) => {
  return (
    <DotContainer>
      <Dot style={{ backgroundColor: color }} />
      <Dot style={{ backgroundColor: color }} />
      <Dot style={{ backgroundColor: color }} />
    </DotContainer>
  );
};

export default BouncingDotsLoader;
