import { Stack, styled, Tab, Typography, Tabs } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(2),
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    height: "4px",
  },
}));

interface StyledTabProps {
  label: string;
  value?: string;
  to?: string;
  href?: string;
  linkcomponent: any;
  toggledrawer?: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab
    disableRipple
    sx={{ px: 0 }}
    component={props.linkcomponent}
    to={props.to}
    href={props.href}
    {...props}
  />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(16),
  marginRight: theme.spacing(6),
  [theme.breakpoints.down("lg")]: {
    marginRight: theme.spacing(2),
  },
  color: "#000000",
  "&.Mui-selected": {
    color: "#000000",
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const StyledLink = styled((props: StyledTabProps) => (
  <Stack component={props.linkcomponent} to={props.to} href={props.href}>
    <Stack
      direction={"row"}
      component="a"
      sx={{
        textDecoration: "none",
        alignItems: "center",
        marginTop: "24px",
        color: "#ffffff",
      }}
      onClick={props.toggledrawer && props.toggledrawer(false)}
    >
      <CircleIcon color={"primary"} sx={{ height: "17px" }} />
      <Typography sx={{ pl: 2, fontWeight: 700, fontSize: "32px" }}>
        {props.label}
      </Typography>
    </Stack>
  </Stack>
))(() => ({}));
