import {
    // AllRequestTypes,
    EmployeeOptions,
    NewPatient,
    Patient,
    PostNewServiceRequest,
    ServiceRequest,
    ThjodskraLeit,
    TimeLog,
    UpdateTimeAllocated,
    User,
} from "../models/ServiceRequests";

import jwt from "jsonwebtoken";
import { validationErrorBody } from "../models/ServiceTypes";
import { JWTPayload } from "../models/JWT";

const tokenStorageKey = "token";

export default class Service {
    protected isLoggedIn: boolean = false;
    private token: string | undefined = undefined;
    private onUnauthorizedError: () => void;

    constructor(onUnauth: () => void) {
        this.onUnauthorizedError = onUnauth;
    }

    public getUserIfLoggedIn: () => User | undefined = () => {
        let token: string | null | undefined = this.token || localStorage.getItem(tokenStorageKey);
        if (!token) {
            return undefined;
        } else {
            if (tokenIsExpired(token)) {
                // TODO: refresh token?
                return undefined;
            } else if (this.token !== token) {
                this.token = token;
            }
        }
        const user = this.parseToken(this.token);

        return user;
    };

    public setTokenAndGetUser: (token: string) => User = (token) => {
        this.token = token;
        localStorage.setItem(tokenStorageKey, token);
        const user = this.parseToken(this.token);
        return user;
    };

    private parseToken: (token: string) => User = (token) => {
        try {
            const payload: JWTPayload = jwt.decode(token) as JWTPayload;
            let user: User = {
                name: payload.name,
                ssn: payload.sub,
                accountName: payload.accountName,
                id: payload.userID,
            };

            return user;
        } catch (e) {
            console.error(e);
            throw e;
        }
    };

    public prepareLogin = () => {
        return this.get<{ id: string }>("/prepareLogin");
    };

    public logOut: () => void = () => {
        localStorage.removeItem(tokenStorageKey);
        this.token = undefined;
    };

    public submitRequest: (req: PostNewServiceRequest) => Promise<string> = (req) => {
        return this.post<string, PostNewServiceRequest>(`/main/postRequest`, req);
    };

    public submitPatient: (pat: NewPatient) => Promise<Patient> = (pat) => {
        return this.post<Patient, NewPatient>(`/main/postEmployee`, pat);
    };
    public updateTimeAllocated: (pat: UpdateTimeAllocated) => Promise<void> = (pat) => {
        return this.patch<void, UpdateTimeAllocated>(`/main/updateTimeAllocated`, pat);
    };

    public getRequests: () => Promise<ServiceRequest[]> = () => {
        return this.get<ServiceRequest[]>(`/main/getRequests`);
    };
    public getRequestTimeLog: (reqID?: string) => Promise<TimeLog[]> = (reqID) => {
        return reqID
            ? this.get<TimeLog[]>(`/main/getRequestTimeLog/${reqID}`)
            : this.get<TimeLog[]>(`/main/getRequestTimeLog`);
    };
    public getEmployees: () => Promise<EmployeeOptions> = () => {
        return this.get<EmployeeOptions>(`/main/getEmployees`);
    };
    public getKtInfo: (kt: string) => Promise<ThjodskraLeit> = (kt) => {
        return this.get<ThjodskraLeit>(`/main/getKtInfo/${kt}`);
    };
    // public getTypes: () => Promise<AllRequestTypes> = () => {
    //     return this.get<AllRequestTypes>(`/main/getTypes`);
    // };

    private get<T>(url: string): Promise<T> {
        return fetch(`/api${url}`, {
            method: "GET",
            headers: {
                ContentType: "application/json",
                Authorization: `Bearer ${this.token}`,
            },
        }).then(this.parseResponse);
    }

    private post<T, U>(url: string, body: U): Promise<T> {
        return fetch(`/api${url}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(body),
        }).then(this.parseResponse);
    }
    private patch<T, U>(url: string, body: U): Promise<T> {
        return fetch(`/api${url}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(body),
        }).then(this.parseResponse);
    }

    private parseResponse = async (res: Response) => {
        if (res.status === 401 || res.status === 403) {
            this.onUnauthorizedError();
        } else if (res.status !== 200) {
            return res
                .json()
                .then((body) => {
                    console.log("Caught error in request: ", body);
                    if (res.status === 4000) {
                        let errors = (body as validationErrorBody).errors;
                        throw new Error(
                            Object.keys(errors)
                                .flatMap((key) => errors[key])
                                .join(", ")
                        );
                    }
                    throw new Error(body?.message || res.statusText || "Eitthvað fór úrskeiðis");
                })
                .catch((err) => {
                    throw new Error(err.message || "Eitthvað fór úrskeiðis");
                });
        } else {
            return res.json();
        }
    };
}

function tokenIsExpired(token: string) {
    const payload = jwt.decode(token) as JWTPayload;
    return !payload?.exp || Date.now() > payload.exp * 1000;
}
