import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const AudnastTheme = responsiveFontSizes(
  createTheme({
    // spacing: 4,
    palette: {
      primary: {
        main: "#2990CC",
      },
      secondary: {
        main: "#2990CC",
      },
      text: {
        primary: "#0c090a",
      },
      info: {
        main: "#F5F7FA",
      },
      background: {
        default: "#F0F0F0",
      },
      // error: {
      //     main: "rgb(126, 135, 140)",
      // },
    },
    typography: {
      fontFamily: "Hypatia Sans Pro,PT Sans Pro,sans-serif",
      h1: {
        fontSize: "2.5rem",
      },
      h2: {
        fontSize: "2.3rem",
      },
      h3: {
        fontSize: "2rem",
      },
      h4: {
        fontSize: "1.8rem",
      },
      h5: {
        fontSize: "1.6rem",
      },
      h6: {
        fontSize: "1.4rem",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            borderRadius: "10px",
            border: "0.5px solid #2990CC",
          },
          paper: {
            boxShadow: "none !important",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "10px",
                border: "0.5px solid #2990CC",
              },
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            "@media print": {
              display: "block!important",
            },
          },
        },
      },
    },
  })
);
