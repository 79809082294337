import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";

import { Control, Controller, FieldValues } from "react-hook-form";
import { FieldOverrides, FieldProps } from "./TextInput";
import { GraphLookup } from "../models/ServiceRequests";

interface DropdownProps<T extends FieldValues>
    extends Omit<FieldProps<T>, "register">,
        Omit<SelectProps, FieldOverrides> {
    control: Control<T, any>;
    button?: JSX.Element;
    selectOptions: GraphLookup[];
}

export function Dropdown<T extends FieldValues>({
    control,
    label,
    disabled,
    button,
    name,
    selectOptions,
    error,
}: DropdownProps<T>) {
    return (
        <Controller
            render={({ field }) => (
                <FormControl
                    sx={{
                        width: "100%",
                    }}
                    error={Boolean(error)}
                    disabled={disabled}
                >
                    <InputLabel>{label}</InputLabel>
                    <Select
                        {...field}
                        sx={{
                            width: "100%",
                            borderRadius: "10px",
                        }}
                        label={label}
                    >
                        {selectOptions.map((itm, idx) => {
                            return (
                                <MenuItem key={`${itm.title}${idx}`} value={itm.id}>
                                    {itm.title}
                                </MenuItem>
                            );
                        })}
                        {button && (
                            <MenuItem
                                value={""}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                }}
                            >
                                {button}
                            </MenuItem>
                        )}
                    </Select>
                    <FormHelperText>{error?.message}</FormHelperText>
                </FormControl>
            )}
            name={name}
            control={control}
        />
    );
}
