import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
export interface MessageProps {
  message: string;
  internalError?: string | Error;
}

function Message({ message, internalError }: MessageProps) {
  return (
    <div>
      <Container>
        <Typography component="div" variant="h5">
          {message}
        </Typography>
        {internalError && (
          <Typography component="div" variant="subtitle1">
            Internal Error:{" "}
            {typeof internalError === "string"
              ? internalError
              : internalError.message}
          </Typography>
        )}
      </Container>
    </div>
  );
}

export default Message;
