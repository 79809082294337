import * as z from "zod";
import { TypeOf } from "zod";

// export interface AllRequestTypes {
//     requestType: GraphLookup[];
//     requestReason: GraphLookup[];
// }

export interface EmployeeOptions {
    employees: Patient[];
    hrDepartment: GraphLookup[];
    // superiors: GraphLookup[];
}
export interface GraphLookup {
    id: string;
    title: string;
}

const ssnregex = new RegExp("^[0-9]*$");
export const NewPatientSchema = z.object({
    title: z.string().min(1, "Name is required").max(70),
    phone: z.string().min(7, "Phone is at lest 7 numbers"),
    email: z.string().email("Invalid email address"),
    ssn: z.string().length(10, "SSN must be 10 numbers").regex(ssnregex),
    svid: z.optional(z.string().max(30, "Svið má ekki vera lengri en 30 stafir")),
    deild: z.optional(z.string().max(30, "Deild má ekki vera lengri en 30 stafir")),
    // superiorID: z.optional(z.string()),
    // superiorID: z.string().min(1, "Velja verður yfirmann"),
    // hRRepresentativeID: z.string(),
    account: z.string().min(1),
    // roleID: z.string().min(1),
});

export interface NewPatient extends TypeOf<typeof NewPatientSchema> {}

export const ServiceRequestSchema = z.object({
    // descriptiveTitle: z.string().min(1, "Heiti vantar").max(70),
    descriptiveTitle: z.optional(z.string()),
    // patientID: z.string().min(1, "Velja verður skjólstæðing"),
    patient: z.object(
        {
            id: z.string().min(1, "Velja verður skjólstæðing"),
            title: z.string().nullable(),
            ssn: z.string().min(10),
        },
        { required_error: "Velja verður skjólstæðing", invalid_type_error: "Velja verður skjólstæðing" }
    ),
    requestedBy: z.string(),
    description: z.string().max(380, "Lýsing má ekki vera lengri en 380 stafir").nullable(),
    timeAllocated: z.string().min(1, "Úthluta verður í það minnsta 1 klst"),
    timeUsed: z.string(),
    account: z.string().min(1),
    hRRepresentativeID: z.string().min(1),
});

export interface NewServiceRequest extends TypeOf<typeof ServiceRequestSchema> {}

export interface PostNewServiceRequest extends Omit<NewServiceRequest, "timeAllocated"> {
    timeAllocated: number;
}

export interface ServiceRequest {
    id: number;
    title: string;
    descriptiveTitle: string;
    account: string;
    dateCreated: string;
    status: string;
    requestedBy: string;
    patient: Patient;
    responsible: GraphLookup;
    description: string;
    timeAllocated: string;
    timeUsed: string;
    modified: string;
}
export interface Patient extends GraphLookup {
    ssn: string;
}
export interface UpdateTimeAllocated {
    id: string;
    occurences: number;
}

export interface PatientDetails {
    ssn: string;
    name: string;
    phone: string;
    email: string;
    accountID: string;
    deparmentID: string;
    fieldID: string;
    supervisorID: string;
    hrID: string;
}

export interface User {
    name: string;
    ssn: string;
    accountName: string;
    id: string;
}
export interface ThjodskraLeit {
    name: string;
}
export interface TimeLog {
    id: string;
    // employeeName: string;
    date: string;
    time: string;
    type: string;
    // description: string;
    project: string;
    descriptiveTitle: string;
}
