import { useLocation } from "react-router-dom";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getIcelandicTime = (date: Date) =>
  date.toLocaleTimeString("is-IS", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[]
];

export type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "."}${P}`
    : never
  : never;

// type Paths<T, D extends number = 10> = [D] extends [never]
//     ? never
//     : T extends object
//     ? {
//           [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
//       }[keyof T]
//     : "";

export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
  : "";

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;
