import { Button, ButtonProps, Typography } from "@mui/material";
import BouncingDotsLoader from "./DotLoader";

export interface CustomIconButtonProps extends ButtonProps {
    title: string;
    icon?: JSX.Element;
    resize?: boolean;
    textMinWidth?: number;
    left?: boolean;
    loading?: boolean;
}

const CustomIconButton = ({
    title,
    icon,
    resize,
    textMinWidth,
    left = false,
    loading,
    ...rest
}: CustomIconButtonProps) => {
    return (
        <Button {...rest}>
            {loading && <BouncingDotsLoader />}
            {left && icon}
            <Typography
                sx={{
                    minWidth: textMinWidth,
                    maxHeight: 48,
                    fontWeight: 700,
                    pr: icon && !left ? 1 : 0,
                    pl: icon && left ? 1 : 0,
                    display: resize ? { xs: "none", sm: "flex" } : {},
                    textAlign: "center",
                }}
            >
                {!loading && title}
            </Typography>
            {!left && icon}
        </Button>
    );
};
export default CustomIconButton;
