import { useEffect, useState } from "react";
import { ServiceRequest, TimeLog, User } from "../models/ServiceRequests";
import { useAsync } from "../utils/UseAsync";
import Message from "../components/Message";
import EnhancedTable from "../components/EnhancedTable";
import { useTheme } from "@mui/material";
import BouncingDotsLoader from "../components/DotLoader";
import moment from "moment";
import DatePeriod from "../components/DatePeriod";
export interface OverviewPageProps {
    getRequests: () => Promise<ServiceRequest[]>;
    getRequestTimeLog: () => Promise<TimeLog[]>;
    user?: User;
}

function Overview({ getRequests, user, getRequestTimeLog }: OverviewPageProps) {
    const theme = useTheme();
    const [requestsFiltered, setRequestsFiltered] = useState<TimeLog[] | null>(null);

    // const { status: reqStatus, value: requests, error: reqError } = useAsync(getRequests, true, false);
    const { status: reqStatus, value: requests, error: reqError } = useAsync(getRequestTimeLog, true, false);

    useEffect(() => {
        if (requestsFiltered === null) setRequestsFiltered(requests);
    }, [requests, requestsFiltered]);
    return (
        <div style={{ flexGrow: 1 }}>
            <DatePeriod requests={requests} dateCol={"date"} setRequestsFiltered={setRequestsFiltered} />
            {reqStatus === "pending" && requests === null && <BouncingDotsLoader color={theme.palette.primary.main} />}
            {user && (
                <>
                    <EnhancedTable
                        items={requestsFiltered || []}
                        searchFields={["date", "type", "type", "descriptiveTitle"]}
                        columns={[
                            { id: "project", label: "Númer beiðnar" },
                            { id: "descriptiveTitle", label: "Beiðni" },
                            { id: "type", label: "Vöruheiti" },
                            {
                                id: "date",
                                label: "Dagsetning",
                                onRender: (itm) => (
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        {`${moment(itm.date).format("D.MMM Y")}`}
                                    </span>
                                ),
                            },
                            { id: "time", label: "Tími" },
                        ]}
                        idCol="id"
                        onClick={() => console.log("hi")}
                    />
                </>
            )}
            {reqStatus === "error" && reqError !== null && <Message message={reqError || "errors"} />}
        </div>
    );
}

export default Overview;
