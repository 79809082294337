import Header, { IHeaderProps } from "./Header";
import { Link, matchPath, Navigate, useLocation, useOutlet } from "react-router-dom";
import { PropsWithChildren } from "react";
import { Stack, styled } from "@mui/material";
import Footer from "./Footer";
import audnastLogo from "../assets/audnast_logo_v2.svg";
import { StyledLink, StyledTab, StyledTabs } from "./Tabs";
import { User } from "../models/ServiceRequests";

export type ProtectedLayoutProps<T extends User> = Pick<IHeaderProps<T>, "user" | "logOut">;

const Root = styled("div")(({ theme }) => ({
    width: "100%",
    minHeight: "100vh",
    backgroundColor: theme.palette.info.main,
    position: "relative",
}));
const PageContainer = styled("div")(({ theme }) => ({
    display: "flex",
    margin: "auto",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: "4rem",
    flexDirection: "column",
    width: "1300px",
    maxWidth: "100%",
    // minHeight: "100vh",
}));

export function ProtectedLayout<T extends User>({ user, logOut }: PropsWithChildren<ProtectedLayoutProps<T>>) {
    const outlet = useOutlet();
    if (!user) {
        return <Navigate to="/login" />;
    }
    return (
        <>
            <Root>
                <PageContainer>
                    <Header
                        user={user}
                        logOut={logOut}
                        headerImg={
                            <Link to="/">
                                <img height={"50px"} src={audnastLogo} alt="Vefgátt Auðnast" />
                            </Link>
                        }
                        Tabs={TabsHeader}
                    />
                    {outlet}
                </PageContainer>
                <Footer />
            </Root>
        </>
    );
}

const TabsHeader = ({
    mobile,
    toggleDrawer,
}: {
    mobile: boolean;
    toggleDrawer?: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
    const paths = [`/requests/*`, `/overview/*`];
    const location = useLocation();
    const match = paths.find((path) => matchPath(path, location.pathname));
    return mobile ? (
        <Stack sx={{ mt: 15 }}>
            <StyledLink label="Beiðnir" to={`/requests`} toggledrawer={toggleDrawer} linkcomponent={Link} />
            <StyledLink label="Yfirlit tíma" to={`/overview`} toggledrawer={toggleDrawer} linkcomponent={Link} />
        </Stack>
    ) : (
        <StyledTabs value={match !== undefined && match.slice(0, -2)} indicatorColor="secondary">
            <StyledTab label="Beiðnir" value={`/requests`} to={`/requests`} linkcomponent={Link} />
            <StyledTab label="Yfirlit tíma" value={`/overview`} to={`/overview`} linkcomponent={Link} />
        </StyledTabs>
    );
};
export default ProtectedLayout;
