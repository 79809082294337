import { Button, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers";

interface DatePeriodProps<T extends Object> {
    requests: T[] | null;
    dateCol: keyof T;
    setRequestsFiltered: React.Dispatch<React.SetStateAction<T[] | null>>;
}

const DatePeriod = <T extends Object>({ requests, dateCol, setRequestsFiltered }: DatePeriodProps<T>) => {
    const [from, setFrom] = useState<Moment | null>(null);
    const [to, setTo] = useState<Moment | null>(null);

    useEffect(() => {
        if (to && from) {
            setRequestsFiltered(requests?.filter((r) => moment(r[dateCol] as string).isBetween(from, to)) || null);
        } else if (to) {
            setRequestsFiltered(requests?.filter((r) => moment(r[dateCol] as string).isBefore(to)) || null);
        } else if (from) {
            setRequestsFiltered(requests?.filter((r) => moment(r[dateCol] as string).isAfter(from)) || null);
        } else {
            setRequestsFiltered(null);
        }
    }, [from, to, setRequestsFiltered, requests, dateCol]);

    return (
        <Stack direction={"row"} spacing={3} mb={3}>
            <DatePicker
                label="Frá"
                value={from}
                onChange={(newValue) => {
                    setFrom(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                showDaysOutsideCurrentMonth
                InputProps={{ sx: { backgroundColor: "#ffffff", borderRadius: "10px" } }}
            />
            <DatePicker
                label="Til"
                value={to}
                onChange={(newValue) => {
                    setTo(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                showDaysOutsideCurrentMonth
                InputProps={{ sx: { backgroundColor: "#ffffff", borderRadius: "10px" } }}
            />
            <Button
                variant="outlined"
                sx={{ borderRadius: "10px" }}
                disabled={!to && !from}
                onClick={() => {
                    setFrom(null);
                    setTo(null);
                }}
            >
                Hreinsa dagsetningar
            </Button>
        </Stack>
    );
};
export default DatePeriod;
