import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const Footer = () => {
    const path = useLocation().pathname;
    return (
        <Typography
            sx={{
                color: path === "/login" ? "#ffffff" : "#000000",
                height: "4rem",
                width: "100%",
                whiteSpace: "pre-wrap",
                fontSize: { xs: "0.60rem", sm: "0.75rem" },
                bottom: "0px",
                position: "absolute",
                textAlign: "center",
                lineHeight: "4rem",
            }}
        >
            {"Auðnast ehf.  |  Grensásvegi 50 - 108 Reykjavík  |  audnast@audnast.is  |  482-4004"}
        </Typography>
    );
};
export default Footer;
