import { Typography, Button, styled } from "@mui/material";

import audnastLogo from "../assets/audnast_logo_v2.svg";
import loginBackgroundImg from "../assets/audnast-login.jpg";

import { useQuery } from "../utils/utils";
import { useCallback, useEffect } from "react";
import { commonRoutes } from "../routes/routes";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { redirect } from "react-router-dom";
import Footer from "../components/Footer";

import {
    TextField,
    MenuItem,
    CircularProgress,
} from "@mui/material";

import { generateHashString, calculateVcode } from "../utils/crypto";

const Root = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    background: `url("${loginBackgroundImg}")`,
    width: "100%",
    minWidth: "100%",
    height: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
}));

const CardContainer = styled("div")(({ theme }) => ({
    margin: "auto",
    padding: theme.spacing(4),
    height: "475px",
    width: "100%",
    maxWidth: "400px",
    borderRadius: 16,
    backgroundColor: theme.palette.info.main,
    justifyContent: "space-evenly",
    display: "flex",
    flexDirection: "column",
}));
export interface LoginProps {
    setToken: (token: string) => void;
    prepareLogin: () => Promise<{ id: string }>;
}

export default function Login({ setToken, prepareLogin: _prepLogin }: LoginProps) {
    const query = useQuery();
    const [error, setError] = useState<string | null>(query.get(commonRoutes.login.params.errorMessage));
    const [loginAuthId, setLoginAuthId] = useState<string | undefined>();

    const [pinHash, setPinHash] = useState<string | undefined>();
    const [loginHint, setLoginHint] = useState<string>('');
    const [selectedEidMethod, setSelectedEidMethod] = useState<string>("sim");

    const prepLogin = useCallback(() => {
        _prepLogin().then((r) => setLoginAuthId(r.id));
    }, [_prepLogin, setLoginAuthId]);

    useEffect(() => {
        const token = query.get(commonRoutes.login.params.token);

        if (token) {
            setToken(token);
        } else if (!loginAuthId) {
            prepLogin();
        }

        if (error && query.get(commonRoutes.login.params.errorMessage)) {
            // setPinHash(undefined)
            redirect(`/${commonRoutes.login.root}`);
        }
    }, [query, setToken, error, loginAuthId, prepLogin]);

    return (
        <Root>
            {error && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(error)}
                    autoHideDuration={8000}
                    onClose={() => {
                        setError(null);
                    }}
                    message={error}
                />
            )}
            <CardContainer>
                <img src={audnastLogo} alt="audnast-logo" height={153} />
                <Typography sx={{ alignSelf: "center", padding: "5px" }}>Velkomin í vefgátt Auðnast</Typography>
                <form method="post" action={'/eid/login'}>
                    <TextField
                        fullWidth
                        name="authType"
                        id="authType"
                        select
                        label="Tegund auðkenningu"
                        defaultValue="sim"
                        variant="standard"
                        sx={{ textAlign: "left", marginBottom: 2 }}
                        onChange={(e) => setSelectedEidMethod(e.target.value)}
                    >
                        {[
                            { value: "sim", label: "Rafræn skilríki" },
                            { value: "app", label: "Auðkennisapp" },
                        ].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <>
                        <TextField
                            id="pinHash"
                            label="pinHash"
                            variant="standard"
                            name="pinHash"
                            value={pinHash}
                            hidden={true}
                            sx={{ display: "none" }}
                        />
                        <TextField
                            id="loginHint"
                            label={selectedEidMethod === "sim" ? "Símanúmer" : "Kennitala"}
                            variant="standard"
                            name="loginHint"
                            value={loginHint}
                            onChange={(e) => setLoginHint(e.target.value)}
                            fullWidth
                            sx={{ marginBottom: 4 }}
                        />
                        <Button
                            type="submit"
                            sx={{
                                ":hover": { boxShadow: "none" },
                                boxShadow: "none",
                                height: "62px",
                                borderRadius: "16px",
                            }}
                            onClick={() => {
                                setPinHash(generateHashString(loginHint));
                            }}
                            // disabled={!loginAuthId || pinHash !== undefined}
                            disabled={!loginAuthId}
                            color="secondary"
                            variant="contained"
                            fullWidth
                        >
                            {pinHash !== undefined ?
                                <>
                                    <CircularProgress sx={{ mr: 2, "&.MuiCircularProgress-root": { color: "white" } }} />
                                    <Typography variant="body1" fontWeight={600}>
                                        PIN: {calculateVcode(pinHash)}
                                    </Typography>
                                </>
                                :
                                <Typography variant="body1" fontWeight={600}>
                                    Innskrá
                                </Typography>
                            }
                        </Button>
                    </>

                </form>
            </CardContainer>
            <Footer />
        </Root>
    );
}
