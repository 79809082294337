import { OutlinedTextFieldProps, TextField, TextFieldProps } from "@mui/material";

import {
    FieldValues,
    FieldError,
    FieldPath,
    Message,
    RegisterOptions,
    UseFormRegister,
    ValidationValueMessage,
    UseFormSetValue,
} from "react-hook-form";
import { NewPatient, NewServiceRequest } from "../models/ServiceRequests";

// type TextInputProps<T extends DefaultNewServiceRequest | NewPatient> =
//   FieldProps<T> &
//     Omit<OutlinedTextFieldProps, FieldOverrides> & {
//       variant?: TextFieldProps["variant"];
//     } & (
//       | { number: true; setVal: UseFormSetValue<any> }
//       | { number?: undefined; setVal?: undefined }
//     );
type TextInputProps<T extends NewServiceRequest | NewPatient> = FieldProps<T> &
    Omit<OutlinedTextFieldProps, FieldOverrides> & {
        variant?: TextFieldProps["variant"];
        number?: boolean;
        setVal?: UseFormSetValue<any>;
    };

export function TextInput<T extends NewServiceRequest | NewPatient>({
    register,
    label,
    name,
    options,
    variant = "outlined",
    number,
    setVal,
    error,
    helperText,
    ...rest
}: TextInputProps<T>) {
    return (
        <TextField
            label={label}
            sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": { borderRadius: "10px" },
            }}
            error={Boolean(error)}
            helperText={error?.message || helperText}
            variant={variant}
            {...register(name, {
                valueAsNumber: number,
                onChange: (e) => {
                    setVal && setVal(name, e.target.value.replace(/\D/g, ""));
                },
                ...options,
            })}
            {...rest}
        />
    );
}

export interface FieldProps<T extends FieldValues | NewServiceRequest> {
    name: FieldPath<T>;
    register: UseFormRegister<T>;
    options?: RegisterOptionsWithOnlyMessages;
    error?: FieldError;
    tooltipText?: string;
}
export type RegisterOptionsWithOnlyMessages = {
    [Property in keyof RegisterOptions]: Property extends keyof Pick<RegisterOptions, "required">
        ? Message
        : Extract<RegisterOptions[Property], ValidationValueMessage> extends never
        ? RegisterOptions[Property]
        : Extract<RegisterOptions[Property], ValidationValueMessage>;
};
export type FieldOverrides = "name" | "error" | "variant";
