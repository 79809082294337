import {
    Button,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import { EmployeeOptions, NewServiceRequest, ServiceRequest, TimeLog } from "../models/ServiceRequests";
import AddIcon from "@mui/icons-material/Add";
import { Control, FieldError, FieldErrorsImpl, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { TextInput } from "./TextInput";
import CustomIconButton from "./Button";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useState } from "react";
import { useAsync } from "../utils/UseAsync";
import BouncingDotsLoader from "./DotLoader";
import { AutoComplete } from "./AutoComplete";
import { Dropdown } from "./Dropdown";

type RequestFormProps = {
    updateTimeAllocated: () => Promise<void>;
    closeForm: () => void;
    employeeopt: EmployeeOptions;
    setReqForm: React.Dispatch<React.SetStateAction<boolean>>;
    data?: ServiceRequest;
    accountName: string;
    trigger: () => void;
    register: UseFormRegister<NewServiceRequest>;
    errors: FieldErrorsImpl<NewServiceRequest>;
    control: Control<NewServiceRequest, any>;
    setValue: UseFormSetValue<NewServiceRequest>;
    isSubmitting: boolean;
    handleSubmit: () => void;
    btnDisabled: boolean;
    watch: UseFormWatch<NewServiceRequest>;
    getRequestTimeLog: (reqID: string) => Promise<TimeLog[]>;
};

export const RequestForm = ({
    register,
    setReqForm,
    setValue,
    updateTimeAllocated,
    isSubmitting,
    handleSubmit,
    accountName,
    btnDisabled,
    closeForm,
    errors,
    data,
    control,
    watch,
    employeeopt,
    getRequestTimeLog,
}: RequestFormProps) => {
    const theme = useTheme();
    const read = data !== undefined;
    const wrappedGetRequestTimeLog = useCallback(
        () => (read ? getRequestTimeLog(`${data.id}`) : Promise.resolve(null)),
        [getRequestTimeLog, data?.id, read]
    );
    const { status: timeLogStatus, value: timeLog } = useAsync(wrappedGetRequestTimeLog);
    const [patientExists, setPatientExists] = useState(false);
    useEffect(() => {
        if (watch("patient.ssn") && watch("patient.ssn").length === 10) {
            const pat = employeeopt.employees.find((e) => e.ssn === watch("patient.ssn"));
            if (pat) {
                setPatientExists(false);
                setValue("patient.ssn", pat?.ssn || "");
                setValue("patient.id", pat?.id || "");
                setValue("patient.title", pat?.title || "");
            } else {
                setPatientExists(true);
            }
        } else {
            setPatientExists(false);
            setValue("patient.id", "");
            setValue("patient.title", "");
        }
    }, [watch("patient.ssn")]);
    return (
        <>
            <IconButton
                size="small"
                sx={{
                    p: "2px",
                    position: "absolute",
                    right: "16px",
                    top: "16px",
                }}
                onClick={closeForm}
            >
                <CloseIcon sx={{}} />
            </IconButton>
            <form className="form">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{read ? accountName : "Ný beiðni"}</Typography>
                    </Grid>
                    {read && (
                        <Grid item xs={12} sm={6}>
                            <TextInput
                                label="Beiðni"
                                register={register}
                                name={"descriptiveTitle"}
                                error={errors["descriptiveTitle"]}
                                disabled={read}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            label="Fyrirtæki"
                            register={register}
                            name={"account"}
                            disabled={true}
                            error={errors["account"]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Dropdown
                            control={control}
                            name={"hRRepresentativeID"}
                            label={"Tengiliður mannauðs"}
                            error={errors["hRRepresentativeID"]}
                            disabled={read}
                            selectOptions={employeeopt.hrDepartment}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            label="Áætlaður tími (klst)"
                            register={register}
                            error={errors["timeAllocated"]}
                            name={"timeAllocated"}
                            setVal={setValue}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            label="Óskað eftir af"
                            register={register}
                            error={errors["requestedBy"]}
                            name={"requestedBy"}
                            disabled={read}
                        />
                    </Grid>
                    {read && (
                        <Grid item xs={12} sm={6}>
                            <TextInput label="Notaður tími (klst)" register={register} name={"timeUsed"} disabled />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            register={register}
                            name={"patient.ssn"}
                            label={"Kennitala skjólstæðings"}
                            error={errors["patient"]?.id || (errors["patient"] as FieldError)}
                            disabled={read}
                            helperText={patientExists && "Ekki til skráð í kerfi, ýttu á plúsinn til að bæta við."}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            disabled={!patientExists}
                            onClick={() => setReqForm(false)}
                            variant="outlined"
                            sx={{ height: "56px", width: "100%", borderRadius: "10px" }}
                        >
                            <AddIcon sx={{ color: "primary" }} />
                            <Typography
                                sx={{
                                    maxHeight: 48,
                                    fontWeight: 700,
                                    display: { xs: "flex", sm: "none" },
                                    textAlign: "center",
                                    pl: 2,
                                }}
                            >
                                Nýr starfsmaður
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInput
                            register={register}
                            name={"patient.title"}
                            label={"Nafn skjólstæðings"}
                            error={errors["patient"]?.title || (errors["patient"] as FieldError)}
                            disabled={true}
                            InputLabelProps={{ shrink: watch("patient.title") ? true : undefined }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput
                            label="Lýsing (hámark 380 stafir)"
                            disabled={read}
                            register={register}
                            error={errors["description"]}
                            name={"description"}
                            multiline
                            minRows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {timeLogStatus === "pending" ? (
                            <BouncingDotsLoader color={theme.palette.primary.main} />
                        ) : timeLogStatus === "success" && timeLog !== null ? (
                            <TimeLogTable data={timeLog} />
                        ) : null}
                    </Grid>
                    <Grid item xs={12}>
                        {!read ? (
                            <CustomIconButton
                                onClick={handleSubmit}
                                title={"Senda beiðni"}
                                variant="contained"
                                sx={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    height: "56px",
                                    "&.Mui-disabled": {
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                    },
                                    boxShadow: "none",
                                }}
                                loading={isSubmitting}
                                disabled={isSubmitting}
                            />
                        ) : (
                            <CustomIconButton
                                onClick={updateTimeAllocated}
                                title={"Uppfæra tíma"}
                                variant="contained"
                                sx={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    height: "56px",
                                    "&.Mui-disabled": {
                                        backgroundColor: (theme) => (isSubmitting ? theme.palette.primary.main : ""),
                                    },
                                    boxShadow: "none",
                                }}
                                loading={isSubmitting}
                                disabled={isSubmitting || btnDisabled}
                            />
                        )}
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

interface TimeLogTableProps {
    data: TimeLog[] | null;
}

const TimeLogTable = ({ data }: TimeLogTableProps) => {
    if (data === null) return <></>;
    return (
        <TableContainer component={Paper} sx={{ border: "1px solid #bdbdbd", boxShadow: "none" }}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 600 }}>Tími</TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                            Dagsetning
                        </TableCell>
                        <TableCell sx={{ fontWeight: 600 }} align="right">
                            Skýring
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, idx) => (
                        <TableRow key={row.date + idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {row.time}
                            </TableCell>
                            <TableCell align="right">
                                {`${new Date(row.date).toLocaleDateString()}`.replaceAll("/", ".")}
                            </TableCell>
                            <TableCell align="right">{row.type}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
